<template>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <v-progress-circular :size="70" :width="4" :color="gray" indeterminate></v-progress-circular>
    </div>
</template>

<script>
export default {
    created() {
        const stripe = window.Stripe("pk_test_51IktLPBBdwKOkIb9uJ6UVs0nrjOCkDZBFuDO4QCZRubMz9T0yfadu1F8qYxnYvz62bJcp6t75Hnj8M7tgZsShsmL00ly5FZtAF");
        stripe.redirectToCheckout({ sessionId: this.$route.params.id });
    }
}
</script>